import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc } from "firebase/firestore";

import "./App.css";
import "../node_modules/video-react/dist/video-react.css";
import { Player } from "video-react";

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
    apiKey: "AIzaSyAlW4qMzrEef_Zw7S8IvcrMcn1qNX200Zo",
    authDomain: "havimas.firebaseapp.com",
    databaseURL: "https://havimas.firebaseio.com",
    projectId: "havimas",
    storageBucket: "havimas.appspot.com",
    messagingSenderId: "486665841188",
    appId: "1:486665841188:web:6d3eef3f3b83ddb70506b1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

function App() {
    const [input, setInput] = useState("");
    const [correctAnswer, setCorrectAnswer] = useState(false);

    const [code, setCode] = useState("");

    console.log(input);

    useEffect(() => {
        getCode();
    }, []);

    useEffect(() => {
        if (!correctAnswer && input === code && code !== "") {
            setCorrectAnswer(true);
        }
    }, [input]);

    const getCode = async () => {
        const docRef = doc(db, "code", "PGXGo4DpXVoFg5mMn1V2");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const dlCode = (docSnap.data() as any).secretCode;
            setCode(dlCode);
        }
    };

    return (
        <div className="App">
            {!correctAnswer && (
                <div>
                    <p>Secret code</p>
                    <input
                        placeholder="_ _ _ _ _ _ _"
                        type="text"
                        name="name"
                        value={input}
                        onChange={(event) => setInput(event.target.value)}
                    />
                </div>
            )}
            {correctAnswer && (
                <Player>
                    <source src="credits.mp4" />
                </Player>
            )}
        </div>
    );
}

export default App;
